<template>
  <base-link
    v-if="target?.url"
    :to="target.url"
    :target="target.targetAttribute"
    class="relative hover:underlined fw-light"
    :class="{ 'flex items-center gap-x-1 ': icon }"
    @click="handleClick(title)"
  >
    {{ title }}
    <vf-icon v-if="icon" :name="icon" :dir size="lg" class="-my-4 c-brand-4" />
  </base-link>
  <template v-else>
    <p v-if="title" class="fw-bold !fw-normal">
      {{ title }}
    </p>
    <cms-rich-text v-if="richText" :content="{ richText, type: 'VfCanvasRichText' }" />
  </template>
</template>

<script setup lang="ts">
import type { Icon } from '#types/target'

const props = defineProps<{
  content: any
  footerItemL1ContextKey?: string
}>()

const { $gtm } = useNuxtApp()

const { target, title, richText, icon: { icon, dir } = {} as Icon, name } = props.content

const l1 = props.footerItemL1ContextKey || name.split(' - ')[1]
const handleClick = (title: string) =>
  $gtm.push('footer.onClickFooterLink', `${fullCapitalize(l1)} - ${fullCapitalize(title)}`)
</script>

<template>
  <div ref="elementRef" v-style:bg="backgroundColor" class="grid" @click="handlePromotionClick">
    <div class="relative md:grid-area-stack">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        :lazy
        fit="cover"
        class="full"
      />
      <base-video
        v-else-if="responsiveMedia.video"
        v-bind="video"
        ref="videoRef"
        :autoplay="!lazy"
        :controls="false"
        loop
        muted
        class="full cover"
      />
      <base-link
        v-if="mediaLink"
        :to="mediaLink.url"
        :target="mediaLink.targetAttribute"
        aria-hidden="true"
        tabindex="-1"
        class="absolute-0"
      />
    </div>
    <div
      class="z-1 px-4 md:pointer-events-none md:flex md:items-center md:px-12 md:grid-area-stack <md:my-6"
      :class="[contentAlignmentSmall[alignmentSmall], contentAlignmentLarge[alignmentLarge]]"
    >
      <div
        v-style="{ 'bg': backdrop, 'max-w': { md: '60%', lg: '40%' } }"
        class="space-y-2 <md:bg-transparent md:p-6 md:-mx-6 lg:space-y-4"
      >
        <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
          {{ overline }}
        </p>
        <h2
          v-if="title"
          v-style:c="titleColor"
          :class="titleStyle"
          data-test-id="cms-banner-title"
        >
          <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
            {{ title }}
          </base-link>
          <template v-else>
            {{ title }}
          </template>
        </h2>
        <p
          v-if="subtitle"
          v-style:c="subtitleColor"
          :class="subtitleStyle"
          data-test-id="cms-banner-text"
        >
          {{ subtitle }}
        </p>
        <cms-rich-text v-if="content.richText" v-style:c="subtitleColor" class="pointer-within" :content="{ richText: content.richText }" />
        <div
          v-if="linkType !== 'No-CTA' && targets.length"
          class="pointer-within f-col gap-4 md:flex-row md:wrap lg:gap-6 not-first:pt-4"
          :class="[ctaAlignmentSmall[alignmentSmall], ctaAlignmentLarge[alignmentLarge], equalTargetSizeClass]"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type {
  BannerImageAlignmentLarge,
  BannerImageAlignmentSmall,
  BannerImageContent
} from '#types/components/cms/banner-image'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const { content } = defineProps<{
  content: BannerImageContent
}>()

const {
  alignmentLarge,
  alignmentSmall,
  backdrop,
  backgroundColor,
  equalTargetSize,
  linkType,
  media,
  mediaTarget,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia, getVideo } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

const contentAlignmentLarge: Partial<Record<BannerImageAlignmentLarge, string>> = {
  'left-center': 'md:justify-center',
  'left-right': 'md:justify-end',
  'center-left': 'md:text-center',
  'center-center': 'md:text-center md:justify-center',
  'center-right': 'md:text-center md:justify-end'
}

const contentAlignmentSmall: Partial<Record<BannerImageAlignmentSmall, string>> = {
  center: '<md:items-center <md:text-center'
}

const ctaAlignmentLarge: Partial<Record<BannerImageAlignmentLarge, string>> = {
  'center-left': 'md:text-center md:justify-center',
  'center-center': 'md:text-center md:justify-center',
  'center-right': 'md:text-center md:justify-center'
}

const ctaAlignmentSmall: Record<BannerImageAlignmentSmall, string> = {
  left: '<md:items-start',
  center: '<md:items-center <md:text-center'
}

const equalTargetSizeClass = targets.length > 1 && [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:auto-rows-fr <md:items-stretch',
  getValueForBreakpoint('md', equalTargetSize) && 'md:grid md:auto-rows-fr md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }
})
</script>
